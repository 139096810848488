// EXEMPT
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { getBungieOAuthProps } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import {
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogWindow,
} from "@/shared/Dialog.jsx";
import FullscreenOverlay from "@/shared/FullscreenOverlay.jsx";

const Styled = {
  modalCss: () => css`
    --game-color: #efba52;

    ul {
      padding-left: var(--sp-4);

      list-style: disc;
    }

    ul,
    p,
    button {
      margin-bottom: var(--sp-2);
    }

    .link {
      cursor: pointer;
      color: var(--red);

      &:hover {
        text-decoration: underline;
      }
    }

    .auth-btn {
      background-color: var(--game-color);
      font-size: 1rem;
      font-weight: 600;
    }
  `,
};

export function InfoModal({
  hasToken,
  onClose,
}: {
  hasToken: boolean;
  onClose(): void;
}) {
  const { t } = useTranslation();

  return (
    <FullscreenOverlay isOpen onModalClose={onClose}>
      <DialogWindow width={420} className={Styled.modalCss()}>
        <DialogContent>
          <DialogHeader
            title={t("destiny2:infoModal.title", "Welcome to Blitz.gg!")}
            onPressClose={onClose}
          >
            <p className="type-article-headline shade0">
              {t("destiny2:infoModal.subtitle", "Level Up Your Guardian!")}
            </p>
          </DialogHeader>
          <DialogBody className="type-body2 center shade1">
            <p>
              <Trans i18nKey="destiny2:infoModal.copy">
                Blitz.gg Item Manager is here to help you optimize your in-game
                experience. Manage your items, create custom loadouts, and stay
                on top of your inventory—all in one place!
              </Trans>
            </p>
            <p className="shade0">
              {t("destiny2:infoModal.whatYouCan", "What you can do:")}
            </p>
            <ul>
              <li>
                {t(
                  "destiny2:infoModal.items.organize",
                  "Organize and access your gear with ease",
                )}
              </li>
              <li>
                {t(
                  "destiny2:infoModal.items.buildEquip",
                  "Quickly build and equip loadouts",
                )}
              </li>
              <li>
                {t(
                  "destiny2:infoModal.items.transfer",
                  "Effortlessly transfer items between characters",
                )}
              </li>
              <li>
                {t(
                  "destiny2:infoModal.items.trackLimits",
                  "Track inventory limits and optimize space",
                )}
              </li>
            </ul>
            <p>
              {t(
                "destiny2:infoModal.cta.copy",
                "Get ready to streamline your gaming and play smarter with Blitz.gg.",
              )}
            </p>
            <div className="flex column gap-4 align-center">
              {!hasToken && (
                <Button
                  className={"auth-btn"}
                  textColor="var(--shade10)"
                  size={"large"}
                  {...getBungieOAuthProps()}
                >
                  {t("destiny2:auth:button", "Auth with Bungie")}
                </Button>
              )}
              <a href="/destiny2/faq" className="link">
                {t("destiny2:infoModal.howItWorks", "How It Works")}
              </a>
            </div>
          </DialogBody>
        </DialogContent>
      </DialogWindow>
    </FullscreenOverlay>
  );
}
