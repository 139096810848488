import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import {
  OverviewCard,
  SharedStyled,
} from "@/game-destiny2/components/LoadoutBuilderComponents.jsx";
import {
  ItemPicker,
  usePickerState,
} from "@/game-destiny2/components/LoadoutBuilderPickers.jsx";
import { BUCKETS } from "@/game-destiny2/constants/inventory-buckets.mjs";
import {
  ARMOR_STAT_MAP,
  ARMOR_STAT_ORDER,
} from "@/game-destiny2/constants/stats.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import { dataIsLoaded } from "@/util/wiki.mjs";

export const LoadoutBuilderArmor = ({
  selectedCharId,
}: {
  selectedCharId?: string;
}) => {
  const { t } = useTranslation();
  const {
    settings: {
      lastLoggedInIdByGame: { destiny2: bungieId },
    },
    destiny2: {
      characterLoadout: characterLoadoutMap,
      wiki: { armor, mods, stats },
    },
  } = useSnapshot(readState);

  const [_updateInProgress, pickerProps, openPicker] = usePickerState(
    bungieId,
    selectedCharId,
  );

  const characterLoadout = characterLoadoutMap[bungieId] ?? {};
  const character = characterLoadout.characters?.[selectedCharId];
  const equipment = character?.equipment ?? {};
  return (
    <Card padding="0" className={SharedStyled.overviewGridCss()}>
      {(["helmet", "gauntlets", "chest", "leg", "classItem"] as const).map(
        (type) => {
          const instance = equipment[BUCKETS[type]];
          return (
            <OverviewCard
              key={type}
              instance={instance}
              type={type}
              dbMap={armor}
              modMap={mods}
              statMap={stats}
              onOpenPicker={openPicker}
              className="right-aligned"
            >
              <div className="armor-stats">
                {ARMOR_STAT_ORDER.map((hash) => {
                  const { icon: Icon, label } = ARMOR_STAT_MAP[hash];
                  return (
                    <React.Fragment key={hash}>
                      <div className="flex align-center gap-2">
                        <Icon />
                        <span>{t(...label)}</span>
                      </div>
                      <div {...classNames(SharedStyled.meterCss(), "meter")}>
                        <div
                          className="bar"
                          style={{ width: `${instance?.stats[hash] ?? 0}%` }}
                        />
                      </div>
                      <span className="text-end">
                        {instance?.stats[hash] ?? "--"}
                      </span>
                    </React.Fragment>
                  );
                })}
              </div>
            </OverviewCard>
          );
        },
      )}
      {dataIsLoaded(armor, stats) && <ItemPicker {...pickerProps} />}
    </Card>
  );
};
