// EXEMPT
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { Container } from "@/game-destiny2/components/OAuthPermissionBasic.style.jsx";
import { getBungieOAuthProps } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import { LINKED_ACCOUNT_EMPTY_STATE_ROBOT } from "@/hub-settings/constants.mjs";

export const OAuthPermissionBasic = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <video loop autoPlay preload="metadata" controlsList="nodownload" muted>
        <source src={`${LINKED_ACCOUNT_EMPTY_STATE_ROBOT}`} type="video/mp4" />
      </video>
      <p>
        {t(
          "destiny2:permission:request:desc",
          "We need your permission to allow Blitz to view and modify your Destiny 2 guardians and inventory.",
        )}
      </p>
      <Button
        className={"auth-btn"}
        textColor="var(--shade10)"
        size={"large"}
        {...getBungieOAuthProps()}
      >
        {t("destiny2:auth:button", "Auth with Bungie")}
      </Button>
    </Container>
  );
};
