// EXEMPT
import { styled } from "goober";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sp-10);

  width: 100%;
  padding-bottom: var(--sp-10);
  border-bottom: 1px solid var(--shade3-15);
  margin-bottom: var(--sp-4);

  video {
    width: var(--sp-30);
  }

  .auth-btn {
    background-color: #efba52;
    font-size: 1rem;
    font-weight: 600;
  }
`;
