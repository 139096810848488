import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import {
  OverviewCard,
  SharedStyled,
} from "@/game-destiny2/components/LoadoutBuilderComponents.jsx";
import {
  ItemPicker,
  usePickerState,
} from "@/game-destiny2/components/LoadoutBuilderPickers.jsx";
import { Recoil } from "@/game-destiny2/components/Recoil.jsx";
import { BUCKETS } from "@/game-destiny2/constants/inventory-buckets.mjs";
import { STATS, WEAPON_STAT_ORDER } from "@/game-destiny2/constants/stats.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import { dataIsLoaded } from "@/util/wiki.mjs";

export const LoadoutBuilderWeapon = ({
  selectedCharId,
}: {
  selectedCharId?: string;
}) => {
  const { t } = useTranslation();
  const {
    settings: {
      lastLoggedInIdByGame: { destiny2: bungieId },
    },
    destiny2: {
      characterLoadout: characterLoadoutMap,
      wiki: { weapons, mods, stats: statMap },
    },
  } = useSnapshot(readState);

  const [_updateInProgress, pickerProps, openPicker] = usePickerState(
    bungieId,
    selectedCharId,
  );

  const characterLoadout = characterLoadoutMap[bungieId] ?? {};
  const character = characterLoadout.characters?.[selectedCharId];
  const equipment = character?.equipment ?? {};
  return (
    <Card padding="0" className={SharedStyled.overviewGridCss()}>
      {(["kinetic", "energy", "power"] as const).map((type) => {
        const instance = equipment[BUCKETS[type]];
        const stats = instance?.stats ?? {};
        return (
          <OverviewCard
            key={type}
            instance={instance}
            type={type}
            dbMap={weapons}
            modMap={mods}
            statMap={statMap}
            onOpenPicker={openPicker}
            className="right-aligned"
          >
            <div className="type-caption--semi weapon-stats">
              {!!stats[STATS.rpm] && (
                <>
                  <span className="name">{t("destiny2:rpm", "RPM")}</span>
                  <div className="filler" />
                  <span className="amt">{stats[STATS.rpm]}</span>
                </>
              )}
              {WEAPON_STAT_ORDER.map(
                (hash) =>
                  stats[hash] !== undefined && (
                    <React.Fragment key={hash}>
                      <span className="name">{statMap[hash].label}</span>
                      <div {...classNames(SharedStyled.meterCss(), "meter")}>
                        <div
                          className="bar"
                          style={{ width: `${stats[hash] ?? 0}%` }}
                        />
                      </div>
                      <span className="amt">{stats[hash]}</span>
                    </React.Fragment>
                  ),
              )}
              {stats[STATS.recoil] !== undefined && (
                <>
                  <span className="name">{statMap[STATS.recoil].label}</span>
                  <Recoil hideVal value={stats[STATS.recoil]} />
                  <span className="amt">{stats[STATS.recoil]}</span>
                </>
              )}
              {!!stats[STATS.magSize] && (
                <>
                  <span className="name">{statMap[STATS.magSize].label}</span>
                  <div className="filler" />
                  <span className="amt">{stats[STATS.magSize]}</span>
                </>
              )}
              {!!stats[STATS.ammoCap] && (
                <>
                  <span className="name">{statMap[STATS.ammoCap].label}</span>
                  <div className="filler" />
                  <span className="amt">{stats[STATS.ammoCap]}</span>
                </>
              )}
            </div>
          </OverviewCard>
        );
      })}
      {dataIsLoaded(weapons, statMap) && <ItemPicker {...pickerProps} />}
    </Card>
  );
};
